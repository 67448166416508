<template>
  <div>
    <!-- aside section -->
    <Spinner v-if="!cmsContentPending" class="mt-5 mb-5" size="medium" line-fg-color="#1f9979" />
    <section v-else class="aside mt-5">
      <b-container class="aside__content">
        <b-row>
          <b-col cols="12" lg="6">
            <div v-html="$prismic.asHtml(this.aside_title)" class="main-title text-left aside__title"></div>
          </b-col>
          <b-col cols="12" lg="6">
            <a :href="this.aside_button_url" class="btn main-button main-button--wide mt-3">{{ $prismic.richTextAsPlain(this.aside_button_text) }}</a>
          </b-col>
        </b-row>
      </b-container>
    </section>
  </div>
</template>

<script>
import Spinner from 'vue-simple-spinner'
export default {
  name: 'AsideComponent',
  components: {
    Spinner
  },
  data: () => {
    return {
      cmsContentPending: false,
      aside_title: null,
      aside_button_text: null,
      aside_button_url: null
    }
  },
  methods: {
    async getAsideContent () {
      await this.$prismic.client.getSingle('aside')
        .then((document) => {
          // aside section
          this.aside_title = document.data.aside_title
          this.aside_button_text = document.data.aside_button_text
          this.aside_button_url = document.data.aside_button_url.url
          this.cmsContentPending = true
        })
    }
  },
  created () {
    this.getAsideContent()
  }
}
</script>

<style lang="scss" scoped>
  @import "./AsideComponent.scss";
</style>
